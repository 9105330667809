var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-3 text-primary"
  }, [_vm.paymentDetail.type_payment == 'manual' ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', {
    staticClass: "text-light",
    staticStyle: {
      "background-color": "#e83b04"
    }
  }, [_vm._v(_vm._s(_vm.transaction.message) + " ")])], 1), _c('b-card-body', [!_vm.expired ? _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "block": "",
      "bg-variant": "light-primary"
    }
  }, [_c('b-row', {}, [_c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Total Pembayaran ")]), _c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "align": "right"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _vm._v("Bayar dalam ")], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-primary h3",
    attrs: {
      "align": "left"
    }
  }, [_c('b', [_vm._v("Rp" + _vm._s(_vm.formatRupiah(_vm.transaction.amount)))])]), _c('b-col', {
    attrs: {
      "align": "right"
    }
  }, [_c('h4', {
    staticClass: "text-red"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.displayTimer))])])])], 1)], 1) : _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "block": "",
      "bg-variant": "light-primary"
    }
  }, [_c('center', [_c('h1', [_vm._v(" Pembayaran sudah kadaluarsa, Silahkan membeli paket Kembali!! ")])])], 1), !_vm.expired ? _c('b-card-text', {
    staticClass: "text-dark"
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rekening",
      "label-for": "rekening"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.transaction.accounts,
      "item-value": "id",
      "item-text": "name",
      "name": "account",
      "placeholder": "Pilih",
      "return-object": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bukti Bayar",
      "label-for": "bukti_bayar"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "bukti_bayar",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)], 1), _vm.selected ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('center', [_c('h3', [_vm._v(_vm._s(_vm.selected.name))]), _c('span', [_vm._v(" Account Number : "), _c('b', [_vm._v(_vm._s(_vm.selected.number))])]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.selected.information))])])], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.formManualValid,
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.kirim
    }
  }, [_vm._v("Kirim")])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_vm.transaction.amount != 0 ? _c('div', {}, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', {
    staticClass: "text-light",
    staticStyle: {
      "background-color": "#e83b04"
    }
  }, [_vm._v("Ayo, Lakukan Pembayaran Segera Juga!"), _c('br'), _vm._v(" No. invoice " + _vm._s(_vm.transaction.no_invoice))])], 1), _c('b-card-body', [_vm.expired == false ? _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "block": "",
      "bg-variant": "light-primary"
    }
  }, [_c('b-row', {}, [_c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Total Pembayaran ")]), _c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "align": "right"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _vm._v("Bayar dalam ")], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-primary h3",
    attrs: {
      "align": "left"
    }
  }, [_c('b', [_vm._v("Rp" + _vm._s(_vm.formatRupiah(_vm.transaction.amount)))])]), _c('b-col', {
    attrs: {
      "align": "right"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.displayTimer) + " ")])])], 1)], 1) : _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "block": "",
      "bg-variant": "light-primary"
    }
  }, [_c('center', [_c('h1', [_vm._v(" Pembayaran sudah kadaluarsa, Silahkan membeli paket Kembali!! ")])])], 1), _c('b-card-text', {
    staticClass: "text-dark"
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Metode Pembayaran"), _c('br'), _c('b', [_vm._v(_vm._s(_vm.transaction.payment_name))])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.paymentDetail.icon_url,
      "width": "100px"
    }
  })], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm.transaction.pay_code != null ? _c('div', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" No. Virtual Account"), _c('br'), _c('span', [_c('b', [_vm._v(_vm._s(_vm.transaction.pay_code))])])]) : _vm.transaction.qr_url != null ? _c('div', {
    attrs: {
      "align": "left"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.transaction.qr_url,
      "width": "100%"
    }
  })], 1) : _vm.transaction.pay_url != null ? _c('div', {
    attrs: {
      "align": "left"
    }
  }, [_c('a', {
    staticClass: "btn btn-gradient-success mt-1",
    attrs: {
      "target": "_blank",
      "href": _vm.transaction.pay_url
    }
  }, [_vm._v("Lanjutkan pembayaran")])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.transaction.pay_code != null ? _c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('br'), _c('b-button', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.transaction.pay_code,
      expression: "transaction.pay_code",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "flat-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CopyIcon"
    }
  }), _vm._v("Salin ")], 1)], 1) : _vm._e()])], 1)], 1)], 1)], 1)], 1) : _c('div', {}, [_c('b-card-body', [_c('b-card', {
    attrs: {
      "border-variant": "primary",
      "block": "",
      "bg-variant": "light-primary"
    }
  }, [_c('b-row', {}, [_c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Total Pembayaran ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-primary h3",
    attrs: {
      "align": "center"
    }
  }, [_c('b', [_vm._v("Rp" + _vm._s(_vm.formatRupiah(_vm.transaction.amount)))])])], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "to": {
        name: 'snbp'
      }
    }
  }, [_vm._v("Masuk Ke SNBP")])], 1)], 1)])], 1)]), _c('b-col', [_c('div', {
    staticClass: "mb-2"
  }, [_vm.paymentDetail.type_payment == 'payment_gateway' && _vm.transaction.amount != 0 ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    attrs: {
      "align": "left"
    }
  }, [_c('b', [_vm._v("Tata Cara Pembayaran")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.paymentDetail.icon_url,
      "width": "100px"
    }
  })], 1)])], 1)], 1)], 1), _c('b-card-text', [_c('app-collapse', {
    staticClass: "mt-1"
  }, _vm._l(_vm.transaction.instructions, function (item, i) {
    return _c('app-collapse-item', {
      key: i,
      attrs: {
        "title": item.title
      }
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, [_c('ol', _vm._l(item.steps, function (step) {
      return _c('li', [_c('span', {
        domProps: {
          "innerHTML": _vm._s(step)
        }
      })]);
    }), 0)])]);
  }), 1)], 1)], 1)], 1) : _vm._e(), _c('b-card', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "block": "",
      "to": {
        name: 'billing'
      }
    }
  }, [_vm._v("Lihat Billing")])], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }